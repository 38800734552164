import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Powder Coating",
  "description": "For coloring metals.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Electroplating-Anodizing", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Powder coating is the process of electrostatically coating a material's surface before heating or curing them with UV to establish an evenly covered surface. It's only applicable to materials that conduct electricity, such as metals. The types of coating used include Thermoplastic powder coating (PE) and thermosetting powder coating.`}</p>
    <p>{`Thermoplastic coatings have thicker finishes but don't bond chemically with each other. The result is thus recyclable. Thermosetting powder coatings, on the other hand, undergo chemical bonding and are more resistant to high temperatures.`}</p>
    <p>{`Some of the several advantages of using powder coating instead of other liquid coatings include: `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Powder coating lasts longer than liquid coatings. It won't decay throughout most products' life cycles unless damaged.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`They provide thicker coverage than liquid coatings and don't sag or peel as easily as liquid coatings.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Powder coatings are non-toxic and less flammable than wet coatings.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The finish would be much harder than conventional paint coatings, with more even color distribution across the structure.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      